@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
  html{
    @apply scroll-smooth
  }
}
body{
  font-family: 'Poppins', sans-serif;
}


/* p {
  text-align: justify;
  text-justify: inter-word;
} */


#gallary{
  background-color: #1DB459;
  animation: fader 10s linear infinite;
}
